.footer {
  height: 140px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0.9em;
}
@media screen and (max-width: 480px) {
  .footer {
    font-size: 0.8em;
  }
}
