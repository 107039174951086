.modal {
  position: absolute;
  top: 150px;
  left: 10px;
  right: 10px;
  bottom: 200px;
  background-color: white;
  border: 1px solid #ccc;
  overflow: auto;
  border-radius: 10px;
  outline: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contanier_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.complete_text {
  font-weight: bold;
  font-size: 1.3em;
  margin: 15px;
}
