.close_button {
  margin-bottom: 10px;
  width: 65px;
  height: 45px;
  border-radius: 10px;
  background-color: #77c66e;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
}
.close_button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 4px rgba(0, 0, 0, 0.2);
}
.close_button_text {
  color: white;
}
