.modal {
  position: absolute;
  top: 150px;
  left: 10px;
  right: 10px;
  bottom: 200px;
  background-color: white;
  border: 1px solid #ccc;
  overflow: auto;
  border-radius: 10px;
  outline: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contanier_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.complete_text {
  font-weight: bold;
  font-size: 1.3em;
  margin: 15px;
}
.button {
  margin: 10px;
  width: calc(100% - 20px);
  height: 60px;
  border-radius: 15px;
  border: 2px solid #77c66e;
  background-color: #77c66e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 4px rgba(0, 0, 0, 0.2);
}
.button_text {
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}
