.formPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
}
.container {
  max-width: 730px;
  width: 100%;
  background-color: #f4f4f4;
}
.container_content {
  padding: 20px;
  width: 100%;
  background-color: #f4f4f4;
}
.no_symptom {
  padding: 15px;
}

.button {
  margin: 10px;
  width: calc(100% - 20px);
  height: 60px;
  border-radius: 15px;
  border: 2px solid #77c66e;
  background-color: #77c66e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 4px rgba(0, 0, 0, 0.2);
}
.button_text {
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}
.buttons {
  display: flex;
}

.button_back {
  margin: 10px;
  width: calc(50% - 20px);
  height: 60px;
  border-radius: 15px;
  border: 2px solid #77c66e;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.button_back:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 4px rgba(0, 0, 0, 0.2);
}

.button_text_back {
  color: #77c66e;
  font-size: 1.2em;
  font-weight: bold;
}

.button_end {
  margin: 10px;
  width: calc(50% - 20px);
  height: 60px;
  border-radius: 15px;
  border: 2px solid #77c66e;
  background-color: #77c66e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.button_end:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 4px rgba(0, 0, 0, 0.2);
}

.button_text_end {
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}
