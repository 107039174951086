.App {
  background-color: #f4f4f4;
  width: 100vw;
  font-family: Nanum;
}

@font-face {
  font-family: "Nanum";
  font-weight: normal;
  src: url("fonts/Nanum/Nanum.woff2") format("woff2"),
    url("fonts/Nanum/Nanum.woff") format("woff"),
    url("fonts/Nanum/Nanum.otf") format("truetype");
}

@font-face {
  font-family: "Nanum";
  font-weight: bold;
  src: url("fonts/NanumBold/NanumBold.woff2") format("woff2"),
    url("fonts/NanumBold/NanumBold.woff") format("woff"),
    url("fonts/NanumBold/NanumBold.otf") format("truetype");
}
