.header {
  background-color: white;
  height: 60px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo {
  padding: 10px;
  height: 60px;
  margin-right: 8px;
}
.title {
  color: #333333;
  font-size: 1.3em;
}
