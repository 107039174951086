.formPage {
  height: calc(100vh + 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f4f4;
}
.container {
  max-width: 730px;
  width: 100%;
}
.container_content {
  padding: 20px;
  width: 100%;

  background-color: #f4f4f4;
}
.for {
  margin-top: 20px;
  margin-bottom: -10px;
}
.searchAddressButton {
  margin-left: -7px;
  margin-bottom: 5px;
}
.privacy_title {
  margin-right: 20px;
  font-weight: bold;
}
.privacy_agree {
  font-weight: bold;
  font-size: 1em;
}
.button {
  margin: 10px;
  width: calc(100% - 20px);
  height: 60px;
  border-radius: 15px;
  border: 2px solid #77c66e;
  background-color: #77c66e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 4px rgba(0, 0, 0, 0.2);
}
.button_text {
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}
