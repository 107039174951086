.body {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .body {
    height: calc(100vh - 50px);
  }
}
.contanier {
  margin-top: -15%;
  display: flex;
  justify-content: center;
}
.container_content {
  min-width: 0;
  max-width: 500px;
  margin: 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.img {
  text-align: center;
  width: 100%;
  padding: 20px;
}
.info {
  text-align: center;
  margin: 5px 0 18px 0;
}
.title {
  font-size: 1.3em;
  color: #77c66e;
}
th,
td {
  padding: 4px;
  text-align: center;
  font-size: 1.1em;
  padding-left: 5px;
  padding-right: 5px;
}
.box {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 130px;
  border: 1px solid #77c66e;
  border-radius: 12px;
}
.button_top_info {
  margin-top: 17px;
  color: #77c66e;
}
.button {
  margin: 10px;
  width: calc(100% - 20px);
  height: 60px;
  border-radius: 15px;
  border: 2px solid #77c66e;
  background-color: #77c66e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 4px rgba(0, 0, 0, 0.2);
}
.button_text {
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}

.warn_info {
  background-color: white;
  margin-top: 20px;
  width: 90%;
  height: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
